.me {
  height: 40vmin;
  pointer-events: none;
  opacity: 0.98;
}

.imgDiv {
  height: 40vmin;
  animation: 150ms ease-in-out infinite color-change;
  /*scale: '15%';*/
}

.name {
  /*color: 'white';*/
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    /*animation: App-logo-spin infinite 20s linear;*/
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  /*justify-content: center;*/
  font-size: calc(10px + 2vmin);
  /*color: white;*/
}

.App-link {
  /*color: #61dafb;*/
}

@keyframes App-logo-spin {
  from {
    /*transform: rotate(0deg);*/
  }
  to {
    /*transform: rotate(360deg);*/
  }
}

@keyframes color-change {
  0% {
    background-color: teal;
  }
  20% {
    background-color: gold;
  }
  40% {
    background-color: red;
  }
  60% {
    background-color: violet;
  }
  80% {
    background-color: green;
  }
  100% {
    background-color: teal;
  }
}
